<template>
  <div class="p">
    <!-- 购买次数 -->
    <div class="purchaseTime-wrapper" v-if="product.buycs && product.buycs > 0">
        <div :class="['purchaseTimes', product.buysNum90day ? 'arrive_tip_color' : '']">买过<span>{{ product.buycs }}</span>次</div>
    </div>
    <div class="purchaseTime-wrapper" v-else-if="product.goodsSaleQty90BillNoCnt">
      <div class="purchaseTimes">{{ product.goodsSaleQty90BillNoCnt }}人次购买</div>
    </div>
    <!-- 曾经购买 -->
    <div class="purchaseTime-wrapper" v-if="product.isPurchasedWithin180 === 1">
      <div class="purchaseTimes">曾经购买</div>
    </div>
    <div class="pp" @click.stop="jumpdetail()">
      <div class="pic">
        <div class="pic-center">
          <img :src="product.mainImageUrl" />
        </div>

        <!-- 无货，失效 -->
        <div class="product-center-warp" v-if="product.availableNum <= 0 || !product.isInvalid">
          <div v-if="product.availableNum <= 0" class="bj">已售罄</div>
          <div v-else-if="!product.isInvalid" class="bj">失效</div>
        </div>
        <!-- 视频红包-->
        <div class="product-center-warp" v-if="product.videoId" @click.stop="isstate = true">
          <div class="center-video"></div>
          <div class="center-text" v-if="!product.isView">观看视频领{{ product.redbagMoney }}元</div>
        </div>
        <!-- 返利 0差价标签 -->
        <div
          class="tag-bottom-right"
          v-if="product.isZeroPriceDifference || product.productAttribute == '1'"
          :class="[
            product.isZeroPriceDifference ? 'lcj' : '',
            product.productAttribute == '1' ? 'fy' : ''
          ]"
        >
          <div v-if="product.isZeroPriceDifference" class="c1">0</div>
          <div v-if="product.isZeroPriceDifference" class="c">差价</div>
          <div v-else-if="product.productAttribute == '1'" class="c">返利</div>
        </div>
        <!-- 缺货，库存不足 -->
        <div class="bottom" v-if="product.availableNum > 0 && product.availableNum < 100">
          <span v-if="product.isWillNotHave == 1">仅剩{{ product.availableNum }}{{ product.sellUnit }},即将缺货</span>
          <span v-else>仅剩{{ product.availableNum }}{{ product.sellUnit }}</span>
        </div>
        <!-- 对标原研 -->
        <div class="product-center-warp dbyy" v-if="product.productPatent=='1'">
          <div class="db_icon">
            <img src="https://obs.pujian.com/frontend/app/components/db_icon.png" />
          </div>
        </div>
          <div :class="['rgPs', product.isLearning && product.isLearning === 1 && product.areaExclusiveProductCategory === 0 ? 'xsPs' : '']" v-if="product.areaExclusiveProductCategory === 1 || (product.isLearning && product.isLearning === 1)">
              {{ product.areaExclusiveProductCategory === 1 ? '区域独家' : product.isLearning && product.isLearning === 1 ? '学术' : '' }}
          </div>
      </div>
      <div class="price">
        <span v-if="!product.preferenticePrice">定价中</span>

        <template v-else-if="product.isActivityGood">
          <span><span class="tj-bg">特价</span>￥{{ product.activityPrice }}</span>
          <span class="tj-des" v-if="product.activityGoodInfo && product.activityGoodInfo.activityCount">
            {{ product.activityGoodInfo.activityCount }}{{ product.sellUnit }}起购
          </span>
          <span class="del">￥{{ product.preferenticePrice }}</span>
        </template>

        <template v-else-if="judgmentPreferentialType()">
          <span><span class="tj-bg">特价</span>￥{{ product.activityVo.activityStrategyReduceVOS[0].activityPrice }}</span>
          <span class="del">￥{{ product.preferenticePrice }}</span>
        </template>

        <span v-else>￥{{ product.preferenticePrice }}/{{ product.sellUnit }}</span>
      </div>

      <div class="product-name" :title="product.productName + '【' + product.specifications + '】'">
        <span
          v-if="product.productNameHighlight"
          v-html="product.productNameHighlight"
        ></span>
        <span v-else> {{ product.productName }}</span>
        <template v-if="product.specificationsHighlight">
          【<span class="guige" v-html="product.specificationsHighlight"></span>】
        </template>

        <span class="guige" v-else>【{{ product.specifications }}】</span>
      </div>

      <productLabels :itemObject="product"/>

      <!--商品的活动标签-->
      <ProductActivityTags ref="productActivityTagsRef" :key="product.productId" :product="product" />

      <div class="company margin11">
        <span v-if="product.produceFactoryHighlight" v-html="product.produceFactoryHighlight" />
        <span v-else>{{ product.produceFactory }}</span>
      </div>
      <div class="yxq">
        <span style="margin-right: 10px;">效期：{{ product.nearEffectivePeriod }}</span>
        <template v-if="product.grossProfitRate">毛利率{{ product.grossProfitRate }}%</template>
      </div>
      <div class="thsj" style="font-size: 12px;">最后退货时间：{{ product.lastReturnTime }}</div>
      <div class="ns-price" style="display: flex;">
        <span class="thsj" v-if="product.medicalInsurancePrice" style="font-size: 11px; color: #3875f6;">
          医保价: ￥{{ product.medicalInsurancePrice }}/{{ product.medicalUnit }}
          <span style="margin-left: 10px;">医保价差: ￥{{ product.medicalDifferencePrice }}</span>
        </span>
        <span v-else>零售价: {{ product.suggestedPrice + "/" + product.sellUnit }}</span>
      </div>
      <div class="syz" v-if="product.productInstructionHighlight">
        适应症:<span
        v-html="product.productInstructionHighlight"
        :title="product.productInstruction"
      ></span>
      </div>
      <div class="zbz" v-if="product.isMediumPackage">
        <span>中袋装:(1中包装={{ product.mediumPackage}}{{ product.sellUnit }})</span>
      </div>

      <lowestPriceForSimilar ref="lowestPriceForSimilarRef" :product="product" :isShowSimilar="isShowSimilar" @showSimilar="showSimilar" />
      <img class="isControl" v-if="product.factoryControl === 1" src="https://obs.pujian.com/frontend/app/controlSell/controlSell.png" />
    </div>
    <!-- 购物车 -->
    <div  v-if="product.factoryControl === 0">
      <div class="add-card margin11 v2-g-flex-row v2-g-flex-space-between" :class="product.availableNum <= 0 || !product.isInvalid ? 'disabled' : ''" v-if="product.availableNum > 0">
        <div class="num v2-g-flex-column">
          <div class="num-real" v-if="product.isMediumPackage == 1 && product.mediumPackage &&product.mediumPackage > 0">
            实际购买{{ product.mediumPackage * buyCount }}{{ product.sellUnit }}
          </div>
          <div>
            <a class="op" href="javascript:" @click.stop="addCount(-1)">
              <i class="iconfont icon-sami-select"></i>
            </a>
            <input type="number"
                   v-model="buyCount"
                   ref="ballnum"
                   @keyup.enter="modifyCount()"
                   @change="modifyCount()"
                   @blur="modifyCount()"/>
            <a class="op" href="javascript:" @click.stop="addCount(1)">
              <i class="iconfont icon-add-select"></i>
            </a>
          </div>
        </div>
        <button class="btn" type="button" @click.stop="addCard()">
          <img src="@/assets-v2/images/sy_gouwuche.png" style="width: 28px" />
          <span>加入购物车</span>
        </button>
      </div>
      <div v-else-if="product.availableNum == 0 && product.isCheckArrive != 1" class="remind">
        <button type="button" @click.stop="remind">点击到货提醒</button>
      </div>
      <div v-else class="remind disabled">
        <button type="button" @click.stop="cancelRemind">取消提醒</button>
      </div>
    </div>
    <div  class="isControlSale" v-else @click="lookPhone(product)">
      <div class="isControlSale_btn">查看代理联系方式</div>
    </div>
    <!--hover时，商品明细-->
    <div
      class="detail"
      v-if="!isShowSimilar"
      :style="{ left: detailLeft ? 'calc(-450px)' : 'calc(100% + 10px)' }">
      <p class="name">
        {{ product.productName }}
        <span class="guige">【{{ product.specifications }}】</span>
      </p>

      <ul class="basic-info">
        <li class="price">
          <p style="margin-left: 0; color: #e5432e;" v-if="!product.preferenticePrice">定价中</p>
          <template v-else-if="product.isActivityGood">
            <p>特价</p>
            <p style="margin-left: 10px">￥{{ product.activityPrice }}/{{ product.sellUnit }}</p>
            <p class="tj-des" v-if="product.activityGoodInfo && product.activityGoodInfo.activityCount">
              {{ product.activityGoodInfo.activityCount }}{{ product.sellUnit }}起购
            </p>
          </template>
          <template v-else-if="judgmentPreferentialType()">
            <p>特价</p>
            <p>￥{{ product.activityVo.activityStrategyReduceVOS[0].activityPrice }}/{{ product.sellUnit }}</p>
          </template>
          <template v-else>
            <p>采购价格：</p>
            <p>￥{{ product.preferenticePrice }}/{{ product.sellUnit }}</p>
          </template>
        </li>

        <li v-if="product.preferenticePrice && product.medicalInsurancePrice">
          <p>医保价</p>
          <p>￥{{ product.medicalInsurancePrice }}/{{ product.medicalUnit }}</p>
        </li>
        <li>
          <p>零售价</p>
          <p>￥{{ product.suggestedPrice + "/" + product.sellUnit }}</p>
        </li>
        <li v-if="product.grossProfitRate">
          <p>毛利率</p>
          <p>{{ product.grossProfitRate }}%</p>
        </li>

        <li v-if="product.activityVo && product.activityVo.activityStrategyReduceVOS && product.activityVo.activityStrategyReduceVOS.length" class="actli">
          <p>活动</p>
          <!--商品的活动标签-->
          <ProductActivityTags ref="productActivityTagsRef" :key="product.productId" :product="product" />
        </li>
        <li>
          <p>包装</p>
          <p>{{ product.sellUnit }}</p>
        </li>
        <li>
          <p>件包装</p>
          <p>{{ product.packageNumber }}{{ product.sellUnit }}</p>
        </li>
        <li>
          <p>生产厂家</p>
          <p v-html="product.produceFactory"></p>
        </li>
        <li>
          <p>效期</p>
          <p>{{ product.nearEffectivePeriod }}</p>
        </li>
        <li>
          <p>最后退货时间</p>
          <p>{{ product.lastReturnTime }}</p>
        </li>
      </ul>
    </div>
    <Transition
      appear
      @before-appear="beforeEnter"
      @enter="enter"
      @after-appear="afterEnter"
    >
      <div
        class="add-card-ball"
        v-if="showMoveDot"
        ref="ball"
        :style="{ top: top + 'px', left: left + 'px' }"
      ></div>
    </Transition>

    <VideoRedPacket :show="isstate" @close="isstate=false; getGoodsList()" :url="product.videoUrl" :id="product.videoId" />
    <!--药品控制-->
    <addCarModal ref="addCarModalRef" :p_obj="p_obj"/>

    <!--过期/临期资质弹窗-->
    <qualificationsDialog ref="qualificationsDialogRef" @continueToAddPurchases="continueToAddPurchases" />
    <el-dialog :visible.sync="dl_modal" width="327px" :show-close="false">
      <supplier-ruler-pop :rulerData="dl_modal_obj" @popStatus="popStatus"></supplier-ruler-pop>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.p {
  &:hover {
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 10px #e5e5e5;
  }
  width: 226px;
  max-height: 512px;
  background: #ffffff;
  border: 1px solid #ededed;
  text-align: left;
  position: relative;
  cursor: pointer;

  .margin11 {
    margin-top: 9px;
  }
  .purchaseTime-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    overflow: hidden;
    z-index: 6;
    .purchaseTimes {
      position: absolute;
      top: -50px;
      left: -50px;
      font-size: 12px;
      width: 100px;
      height: 100px;
      background-color: #3875f6;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: center;
      transform-origin: center;
      color: #ffffff;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 3px;
      span{
        color: #e5432e;
      }
    }
    .arrive_tip_color{
      background: #E0FEE6;
      color: #007E15;
    }
  }
  ::v-deep .el-dialog__header{
    padding: 0;
  }
  ::v-deep .el-dialog__body{
    padding: 0;
  }
  ::v-deep .el-dialog{
    border-radius: 20px;
  }
}
.pp {
  padding: 0 8px;
  position: relative;
}
.pp:hover ~ .detail {
  display: block;
}
.isControl{
  width: 45px;
  height: 45px;
  position: absolute;
  right: 20px;
  top: 210px;
}
.detail {
  display: none;
  position: absolute;
  right: auto;
  width: 450px;
  padding: 12px 12px;
  top: -10px;
  left: calc(100% + 10px);
  font-size: 0.85rem;
  font-weight: 400;
  z-index: 38;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 2px 1px rgb(0 1 0 / 20%);

  .name {
    font-size: 1rem;
    text-align: left;
    font-weight: 600;
  }

  .price {
    font-size: 0.875rem;
  }

  .basic-info {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 10px 10px;

    li {
      display: flex;
      align-items: center;
      height: 30px;
      width: 100%;
      color: #666666;

      &.price {
        p {
          font-weight: 400;

          &:nth-child(2) {
            color: #e5432e;
          }
        }
      }

      p {
        &:first-child {
          width: 85px;
          text-align: left;
        }
      }

      &.actli {
        height: inherit;
        line-height: inherit;
      }
    }
  }
}

.pic {
  width: 100%;
  height: 208px;
  position: relative;
  font-size: 0.85rem;
  font-weight: 400;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  .pic-center {
    margin: 13px auto;
    width: 174px;
    height: 174px;
    overflow: hidden;

    img {
      margin: 0 auto;
      width: 174px;
      height: 174px;
      display: block;
    }
  }

  .tag-top-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 27px;
    background: #00b0ff;
    color: #fff;
    text-align: center;
    line-height: 27px;
    background: linear-gradient(90deg, #4bb1ff 0%, #207cf5 100%);
    border-radius: 0px 8px 0px 8px;
  }

  .tag-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 43px;
    height: 42px;
    border-radius: 38px 0px 4px 0px;
  }

  .lcj {
    background: linear-gradient(0deg, #1b45f3 0%, #61c6fb 100%);
    color: #ffffff;

    .c {
      position: absolute;
      bottom: 3px !important;
      right: 2px !important;
      font-weight: 600;
    }

    .c1 {
      color: #fff;
      margin-top: 1px;
      padding: 0 10px;
      text-align: right;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .fy {
    background: linear-gradient(0deg, #077ca0 0%, #4ccfd0 100%);
    width: 43px;
    height: 40px;

    .c {
      position: absolute;
      bottom: 5px;
      right: 2px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .product-center-warp {
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 30%);
    top: 0;
    position: absolute;
    bottom: 0;
    z-index: 10;
    &.dbyy{
      background:none;
    }
    .db_icon{
      position: absolute;
      width: 120px;
      height: 120px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .bj {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      background: rgba(0, 0, 0, 0.32);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-align: center;
      line-height: 60px;
      font-size: 0.75rem;
      font-weight: 600;
    }
    .center-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28px;
      height: 28px;
      background: url("../../assets-v2/images/Videoredpacket/shipin_bofang.png");
      border-radius: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-align: center;
      line-height: 60px;
      font-size: 0.75rem;
      font-weight: 600;
    }
    .center-text {
      text-align: center;
      position: absolute;
      top: 70%;
      left: 50%;
      width: 100%;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #ffffff;
      transform: translate(-50%, -50%);
      background: rgba(255,0,0,0.8);
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 32px;
    background: rgb(0 0 0 / 30%);
    border-radius: 0px 0px 4px 4px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
  }
  .db_icon{
    position: absolute;
  }
  .rgPs{
    color: #ffffff;
    background: linear-gradient( 90deg, #FFAAB8 0%, #FF004E 100%);
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 12px;
    padding: 0 10px;
    position: absolute;
    right: 0;
    top: 8px;
    z-index: 37;
    height: 20px;
    line-height: 20px;
  }
  .xsPs{
    background: linear-gradient( 90deg, #EB6F41 0%, #E03B29 100%);;
  }
}

.price {
  margin-top: 11px;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: #e5432e;
  line-height: 16px;

  .del {
    color: #999999;
    text-decoration: line-through;
  }
}

.act ~ .act {
  background: #fff4d7;
}

.act {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: #fff3f3;
  margin-top: 1px;
  border-radius: 9px;
  .act-type {
    width: 40px;
    height: 21px;
    display: inline-block;
    color: #ffffff;
    font-size: 0.65rem;
    border-radius: 9px 0px 9px 9px;
    text-align: center;
    line-height: 21px;
  }

  .act-mj {
    background: linear-gradient(0deg, #fe4b4f 0%, #fea8c1 100%);
  }

  .act-fhb {
    background: linear-gradient(0deg, #fc664c 0%, #fcb466 100%);
  }

  .act-name {
    font-size: 12px;

    margin-left: 5px;

    i {
      color: #e5432e;
      font-style: normal;
    }
  }
}

.product-name {
  margin-top: 11px;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  line-height: 20px;

  .jyb {
    display: inline-block;
    line-height: 18px;
    width: 18px;
    height: 18px;
    background: #3875f6;
    border-radius: 2px;
    color: #ffffff;
    text-align: center;
    font-size: 11px;
  }

  .guige {
    color: #666666;
    font-size: 12px;
  }
}

.ybbm {
  font-size: 12px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  display: flex;

  .bm {
    max-width: 180px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .fz {
    color: #3875f6;
  }
}

.company,
.yxq {
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.thsj {
  height: 20px;
  line-height: 20px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #3875f6;
}

.syz {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  color: #666666;
}

.zbz,
.ns-price {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.add-card {
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #ededed;
  height: 44px;

  .btn {
    display: flex;
    align-items: center;
  }

  &.disabled {
    color: grey;

    .btn,
    input {
      color: #cccccc;
      pointer-events: auto;
    }

    img {
      filter: #cccccc;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
    }
  }

  .num {
    padding: 0 10px;
    justify-content: space-around;

    input {
      display: inline-block;
      width: 40px;
      height: 100%;
      border: none;
      text-align: center;
    }

    .num-real {
      line-height: 12px;
      font-size: 0.5rem;
      height: 12px;
      color: #fe5257;
    }
  }

  .op {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #f5f7f9;
    text-align: center;
    line-height: 18px;
    vertical-align: middle;
    text-decoration: none;
  }

  .btn {
    cursor: pointer;
    color: #3875f6;
    border: none;
  }
}

.remind {
  margin-top: 5px;

  button {
    cursor: pointer;
    height: 34px;
    width: 100%;
    background: #fff2e6;
    border: none;
    color: #ff8212;
    border-radius: 5px;
  }

  &.disabled {
    button {
      background-color: #ededed;
      color: #666;
    }
  }
}

.add-card-ball {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 100px;
  position: fixed;
  z-index: 99999;
}
.isControlSale {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  cursor: pointer;

  .isControlSale_btn {
    width: 120px;
    height: 24px;
    background: #3875F6;
    border-radius: 19px;
    opacity: 1;
    color: #ffffff;
    line-height: 24px;
    padding: 0 5px;
    text-align: center;
    font-size: 12px;
  }
}
</style>

<script>
import mixin from "./mixin";
import qualificationsPopup from '@mixin/qualificationsPopup'
import Dialog from "../../components-v2/v2-dialog/index";
import VideoRedPacket from "./VideoRedPacket.vue";
import addCarModal from '../addCarModal/addCarModal.vue'
import qualificationsDialog from '@/components-v2/qualificationsDialog.vue'
import supplierRulerPop from "@/components-v2/supplierRulerPop/index.vue";
import lowestPriceForSimilar from '@/components-v2/lowestPriceForSimilar.vue'
import ProductActivityTags from '@/components-v2/ProductActivityTags.vue'
import productActivity from '@/components-v2/product/productActivity'
import ProductLabels from "@/components-v2/product/productLabels.vue";

export default {
  mixins: [mixin, qualificationsPopup, productActivity],
  components: {
    ProductActivityTags,
    ProductLabels,
    supplierRulerPop,
    Dialog,
    VideoRedPacket,
    addCarModal,
    qualificationsDialog,
    lowestPriceForSimilar
  },
  data(){
    return{}
  },
  methods: {
    getGoodsList() {
      this.$emit("getGoodsList");
    }
  }
};
</script>
